import React, {useEffect} from 'react';
import {
  Grid2,
  useTheme
} from "@mui/material";
import { Campaign } from "../common/types";
import { Header } from "../components/Header";
import { DataDisplay } from "../components/DataDisplay";
import { CreateCampaignButton } from "../components/CreateCampaignButton";
import { getListOfCampaigns } from "../actions/CampaignActions";
import { useMsal } from "@azure/msal-react";

interface HomeProps {
  isAuthenticated: boolean;
  currentCampaigns: Campaign[];
  setCurrentCampaigns: (campaigns: Campaign[]) => void;
  setMode: (mode: "light" | "dark") => void;
}

export function Home(props: HomeProps) {
  const { instance } = useMsal();
  const isAuthenticated = props.isAuthenticated;
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const { setCurrentCampaigns } = props;

  useEffect(() => {
    getListOfCampaigns(instance).then(list => {
      setLoading(false);
      return setCurrentCampaigns(list);
    });
  }, [instance, isAuthenticated, setCurrentCampaigns]);

  return (
    <Grid2 container spacing={5} style={{ padding: "30px" }}>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <Header
          isAuthenticated={props.isAuthenticated}
          theme={theme}
          setMode={props.setMode}
        />
      </Grid2>
      <Grid2 container size={12} style={{justifyContent: "center", alignItems: "center"}}>
        <DataDisplay
          currentCampaigns={props.currentCampaigns}
          loading={loading}
          theme={theme}
        />
      </Grid2>
      <Grid2 container size={12} style={{alignItems: "center", justifyContent: "center"}}>
        <CreateCampaignButton />
      </Grid2>
    </Grid2>
  );
}