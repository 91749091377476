import {
  Grid2,
  Paper,
  Typography
} from "@mui/material";
import { typeKeys2ReadableTitles } from "../common/constants";
import { Campaign } from "../common/types";
import React from "react";


interface SummaryProps {
  campaign: Campaign,
  paperWidth: number,
  paperHeight: string
}

export function Summary(props: SummaryProps) {
  return (
    <Paper
      square={false}
      elevation={3}
      style={{
        width: props.paperWidth,
        height: props.paperHeight,
        textAlign: "center",
        paddingBottom: "25px"
      }}
    >
      <h2>Summary</h2>
      <Grid2 size={12} container>
        <Grid2 container size={5} direction="column" style={{ alignItems: "flex-start", marginLeft: "50px" }}>
          <ul>
            {Object
              .entries(props.campaign)
              .slice(0, 8)
              .filter(([key, _]) => {
                return ['ic_id', 'isVisible'].indexOf(key) === -1;
              })
              .map(([key, value]) => {
                if (key === "customParameters") {
                  value = Object.values(value).join(", ");
                }
                if (value === "") {
                  return null;
                }
                return (
                  <li style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        wordBreak: "break-all"
                      }}
                    >
                      {typeKeys2ReadableTitles.get(key) + ": " + value}
                    </Typography>
                  </li>
                );
              })
            }
          </ul>
        </Grid2>
        <Grid2 container size={5} direction="column" style={{ alignItems: "flex-start", marginLeft: "50px" }}>
          <ul>
            {Object
              .entries(props.campaign)
              .slice(8, -1)
              .filter(([key, _]) => {
                return ['ic_id', 'isVisible'].indexOf(key) === -1;
              })
              .map(([key, value]) => {
                if (key === "customParameters") {
                  value = Object.values(value).filter(v => { return v !== "" }).join(", ");
                }
                if (value === "") {
                  return null;
                }
                return (
                  <li style={{ marginBottom: "5px" }}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        wordBreak: "break-all"
                      }}
                    >
                      {typeKeys2ReadableTitles.get(key) + ": " + value}
                    </Typography>
                  </li>
                );
              })
            }
          </ul>
        </Grid2>
      </Grid2>
    </Paper>
  );
}

