import React from 'react';
import {
  Grid2,
  useTheme
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Header } from "../components/Header";
import { Summary } from "../components/Summary";
import { URLViewer } from "../components/URLViewer";
import { HomeButton } from "../components/HomeButton";
import { Campaign } from "../common/types";

interface SuccessProps {
  isAuthenticated: boolean;
  setMode: (mode: "light" | "dark") => void;
}

export function Success(props: SuccessProps) {
  const { state } : { state: { URL: string, campaign: Campaign }} = useLocation();
  const theme = useTheme();

  return (
    <Grid2 container spacing={5} style={{ padding: "30px" }}>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <Header
          isAuthenticated={props.isAuthenticated}
          theme={theme}
          setMode={props.setMode}
        />
      </Grid2>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <Summary
          campaign={state.campaign}
          paperWidth={1000}
          paperHeight={"100%"}
        />
      </Grid2>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <URLViewer
          URL={state.campaign.urlString}
          style={{ urlFieldWidth: "50%", titleMarginRight: "50px" }}
        />
      </Grid2>
      <Grid2 container size={12} style={{ alignItems: "center", justifyContent: "center" }}>
        <HomeButton />
      </Grid2>
    </Grid2>
  );
}
